<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      Boards: "Tabuleiros",
      "Created in": "Criado em",
      "Donations Received": "Doações Recebidas",
      "create your first board": "crie seu primeiro tabuleiro",
      "create your new board": "crie seu novo tabuleiro",
      "Start Board": "Iniciar Tabuleiro",
      "you still can't create your first board": "você ainda não pode criar o seu primeiro tabuleiro",
      "you still can't create a new board": "você ainda não pode criar um novo tabuleiro",
    },
    es: {
      Boards: "Tableros",
      "Created in": "Creado en",
      "Donations Received": "Donaciones Recibidas",
      "create your first board": "crea tu primer tablero",
      "create your new board": "crea tu nueva tabla",
      "Start Board": "Junta Tableros",
      "you still can't create your first board": "todavía no puedes crear tu primer tablero",
      "you still can't create a new board": "todavía no puedes crear una nueva tabla",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      create: {
        status: null,
        loading: false,
      },
      boards: null,
    };
  },
  methods: {
    checkBoards() {
      api.get("boards/check").then((response) => {
        this.create.status = response.data.status;
      });
    },
    getBoards() {
      api.get("boards").then((response) => {
        if (response.data.status == "success") {
          this.boards = response.data.list;
        }
      });
    },
    createBoard() {
      this.create.loading = true;

      api
        .post("boards", {
          type: "bronze",
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$router.push("/boards/" + response.data.board.id);
          }
        })
        .finally(() => {
          this.create.loading = false;
        });
    },
  },
  mounted() {
    this.checkBoards();
    this.getBoards();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Boards") }}</h4>
        </div>
      </div>
    </div>

    <div v-if="!boards" class="text-center mb-3">
      <b-spinner small class="ml-2 align-middle" variant="dark"></b-spinner>
    </div>
    <div class="row" v-if="boards && boards.length > 0">
      <div class="col-md-4" v-for="(row, index) in boards" :key="index">
        <router-link tag="a" :to="'/boards/' + row.id" class="text-dark">
          <div class="card">
            <div class="card-body text-center">
              <div>
                <img
                  style="width: 100px"
                  :src="
                    require('@/assets/images/boards/' +
                      row.type +
                      '-' +
                      row.level +
                      '.png')
                  "
                />
              </div>
              <div class="mb-2">
                <strong>#{{ row.id.split("-")[0] }}</strong
                ><br />
                {{ t("Created in") }} {{ row.date }}
              </div>
              <div class="bg-soft-success rounded mt-1 p-2 font-size-16">
                {{ row.received | currency }}
              </div>
              <div class="mt-1 text-uppercase font-size-11">
                {{ t("Donations Received") }}
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <template v-if="boards && create.status == 'active'">
      <div class="card">
        <div class="card-body text-center">
          <div class="mb-3">
            <h1 class="m-0"><i class="lnr lnr-smile font-size-30"></i></h1>
            <template v-if="boards && boards.length == 0">
              {{ t("create your first board") }}
            </template>
            <template v-if="boards && boards.length > 0">
              {{ t("create your new board") }}
            </template>
          </div>
          <button
            :disabled="create.loading == true"
            class="btn btn-default btn-lg"
            v-on:click="createBoard()"
          >
            {{ t("Start Board") }}
            <b-spinner
              v-if="create.loading"
              small
              class="ml-2 align-middle"
              variant="white"
              role="status"
            ></b-spinner>
          </button>
        </div>
      </div>
    </template>
    <template v-else-if="boards && create.status == 'inactive'">
      <div class="card">
        <div class="card-body text-center">
          <h1 class="m-0"><i class="lnr lnr-sad font-size-30"></i></h1>
          <template v-if="boards && boards.length == 0">
            {{ t("you still can't create your first board") }}
          </template>
          <template v-if="boards && boards.length > 0">
            {{ t("you still can't create a new board") }}
          </template>
        </div>
      </div>
    </template>
  </Layout>
</template>
